/**
 * Type: ページ
 * What: インフォメーション
 */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import AccordionMin from '../components/Accordion-min';
import { Button } from '../components/Btn';

const pageTitle = '施設案内';
const pageDescription =
  'E・ZO FUKUOKA（イーゾ フクオカ）の施設内の情報です。ベビーカーやコインロッカー等のサービス情報です。';
const pageSlug = 'information';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <div className="bg-14 info-ttl-box">
      <h3 className="headline">
        <span>施設案内</span>
      </h3>
      <div className="sub-desc">
        <p className="has-text-align-center has-text-weight-bold text is-4 is-4-sp">
          絶景アトラクションや体験型の最新ミュージアムなど、
          <br className="pc" />
          世界に誇れる革新的コンテンツが満載の地上7階建ての
          <br />
          エンターテインメントビル！
        </p>
        <div className="btn-wrap mt-5">
          <Button href="/special/about/" className="btn-primary wide">
            施設概要・フロア紹介はこちら
          </Button>
        </div>
      </div>
    </div>
    <section className="section">
      <div className="container">
        <div className="inner-slim trans">
          <AccordionMin title="営業時間">
            <div className="btn-wrap mb-5">
              <Button href="/schedule/" className="wide">
                営業時間
              </Button>
            </div>
            <p className="list-mark mb0">
              <span>※</span>
              各施設によって異なります。
            </p>
          </AccordionMin>
          <AccordionMin title="駐車場サービス">
            <article>
              <div className="btn-wrap">
                <a
                  href="https://www.softbankhawks.co.jp/stadium/parking/"
                  className="btn-regular wide"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>詳しくはこちらををご覧ください</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>
            </article>
            {/* <article>
              <p>緊急事態宣言期間中におけるみずほPayPayドーム駐車場ご利用料金について</p>
              <div className="table-base">
                <table className="inner-table">
                  <tr>
                    <th>&nbsp;</th>
                    <th>野球日（1日）</th>
                    <th>コンサート日（1日）</th>
                    <th>左記以外</th>
                  </tr>
                  <tr>
                    <th className="has-text-align-center">料金制度</th>
                    <td className="has-text-align-center">時間料金制</td>
                    <td className="has-text-align-center">定額制</td>
                    <td className="has-text-align-center">時間料金制</td>
                  </tr>
                  <tr>
                    <th className="has-text-align-center">普通車</th>
                    <td className="has-text-align-center">最初の1時間300円、<br />以降30分毎に150円ずつ加算</td>
                    <td className="has-text-align-center">3,000円</td>
                    <td className="has-text-align-center">最初の1時間300円、<br />以降30分毎に150円ずつ加算</td>
                  </tr>
                  <tr>
                    <th className="has-text-align-center" rowSpan="2">割引・返金について</th>
                    <td className="has-text-align-center">
                      <p>ドーム、E・ZO各店舗で1円以上ご利用（飲食・コンテンツ・購入）で下記割引になります。</p>
                      <p>平日：5時間まで無料<br />土日祝：3時間まで無料</p>
                    </td>
                    <td className="has-text-align-center">
                      <p>E・ZOご利用の金額に応じて返金対応を致します。</p>
                      <p>2,000円以上ご利用1,000円返金<br />4,000円以上ご利用2,000円返金<br />6,000円以上ご利用3,000円返金</p>
                    </td>
                    <td className="has-text-align-center">
                      <p>ドーム、E・ZO各店舗で1円以上ご利用（飲食・コンテンツ・購入）で下記割引になります。</p>
                      <p>平日：5時間まで無料<br />土日祝：3時間まで無料</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="has-text-align-left">各店舗ご利用時に駐車券をご提示ください。<br />（割引対応できない店舗がございます。レシート持参の上、E・ZO3階インフォメーションにへお越しください。）</td>
                    <td className="has-text-align-left">駐車場料金所にて配備の『入金証明書』とレシートをご持参の上、E・ZO3階インフォメーションにへお越しください。</td>
                    <td className="has-text-align-left">各店舗ご利用時に駐車券をご提示ください。<br />（割引対応できない店舗がございます。レシート持参の上、E・ZO3階インフォメーションにへお越しください。）</td>
                  </tr>
                </table>
                <table className="inner-table">
                  <tr>
                    <th>&nbsp;</th>
                    <th>野球日（1日）</th>
                    <th>コンサート日（1日）</th>
                    <th>左記以外</th>
                  </tr>
                  <tr>
                    <th className="has-text-align-center">大型車<br />（車高2.1m以上）</th>
                    <td className="has-text-align-center" colSpan="3">5,000円（事前予約制）</td>
                  </tr>
                </table>
                <p className="list-mark">
                  <span>※</span>
                  大型車につきましては、割引・返金対象外となります。
                </p>
              </div>
            </article>
            <article>
              <p>
                E・ZO FUKUOKAをご利用の方はみずほPayPayドーム駐車場をご利用ください。
                <br />
                各店舗にて1円以上のご利用（各アトラクション・飲食店舗・よしもと・HKT）で下記割引になります。
              </p>
            </article>
            <article>
              <p className="mb-0">【通常日（野球・イベント非開催日）】</p>
              <p>
                最初の1時間300円、以降30分毎150円ずつ加算

              </p>
            </article>
            <article>
              <p className="mb-0">【野球・コンサート開催日】</p>
              <p>
                E・ZOご利用の金額に応じて返金対応を致します。
                <br />
                駐車場入口にて配布の『入金証明書』をご持参の上、E・ZO3F インフォメーションまでお越しください。
              </p>
            </article>
            <article>
              <p className="mb0 is-size-6">
                <b>普通車&nbsp;3,000円</b>
              </p>
              <p className="mb0 is-size-6">
                <b>大型車（車高2.1m以上）&nbsp;5,000円</b>
              </p>
            </article>
            <article>
              <p className="mb-0">＜BOSS E・ZO FUKUOKA 3F information営業時間＞</p>
              <p>
                デーゲーム：20:00まで ナイトゲーム：23:00まで
              </p>
              <p className="list-mark mb0">
                <span>※</span>
                試合日以外のドームイベント開催日は20:00まで
              </p>
              <p className="list-mark mb0">
                <span>※</span>
                営業時間は予告なく変更になる場合がございます
              </p>
              <p className="list-mark mb0">
                <span className="attention has-text-weight-bold">※営業時間終了後の返金対応はできかねますので早めにお手続きをお済ませください</span>
              </p>
              <p className="list-mark mb0">
                <span>※</span>
                各店舗にて駐車券をご提示ください。4F～RFをご利用の方はチケットをお持ちのうえ、3F informationまでお越しください。
              </p>
              <p className="list-mark mb0">
                <span>※</span>
                試合日については入庫、出庫にお時間がかかる場合がございます。出庫については状況により1時間以上かかる場合もございますのでご注意ください。
              </p>
              <p className="list-mark">
                <span>※</span>
                駐車場サービスについては予告なく終了となる場合がございます。
              </p>
            </article> */}
            <article>
              <div className="btn-wrap">
                <Button href="/access/" className="wide">
                  アクセス等の詳細はこちら
                </Button>
              </div>
            </article>
            <article>
              <h4 className="ttl-strip">
                ドームイベント日の駐車場利用について
              </h4>
              <p>
                ドームイベント時は駐車場の混雑が予想されるため公共交通機関をご利用ください。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="Wi-Fiサービス">
            <p className="mb20">
              館内全エリアにて、Wi-Fi対応機器による公衆無線LAN接続サービスがご利用いただけます。
            </p>
            <article>
              <h4 className="title is-6">1. Wi-Fiの設定</h4>
              <p>
                お手持ちのスマートフォン等でWi-Fi設定画面を開き、「
                <b>SSID : .FREE_Wi-Fi_PASSPORT_J</b>
                」に接続してください
              </p>
              <h4 className="title is-6">2. Wi-Fiの接続</h4>
              <p>
                ブラウザ(Safari、Chrome等)を起動すると、ログイン画面が表示されます。
                表示されたページの内容に沿って、利用登録をしてください。
              </p>
            </article>
          </AccordionMin>
          {/* <AccordionMin title="海外からお越しのお客様へ">
            <p>
              3F informationへポケトークを設置しております。
              <br />
              お困りのお客様はinformationへお声かけください。
            </p>
          </AccordionMin> */}
          <AccordionMin title="お身体が不自由なお客様へ">
            <article>
              <h4 className="title is-6">多目的トイレ</h4>
              <p className="mb0">RF以外の各フロア</p>
              <p className="list-mark">
                <span>※</span>
                4Fは有料エリア（王貞治ベースボールミュージアム）内
              </p>
            </article>
            <article>
              <h4 className="title is-6">オストメイト対応</h4>
              <p className="mb0">1F、3F、4F、7F　多目的トイレ</p>
            </article>
            <article>
              <h4 className="title is-6">大型ベッド設置</h4>
              <p className="mb0">3F、7F　多目的トイレ</p>
            </article>
            <article>
              <h4 className="title is-6">車いす</h4>
              <p>
                3F informationにて貸し出しを行っております。
                <br />
                事前のご予約はできませんのでご了承ください。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="お子様連れのお客様へ">
            <article>
              <h4 className="title is-6">授乳室</h4>
              <p>5F（調乳用温水器、おむつ替えシート2台設置あり）</p>
            </article>
            <article>
              <h4 className="title is-6">おむつ替えシート</h4>
              <p>
                1F、4F 多目的トイレ内
                <br />
                3F、5F、6F、7F 女子トイレ内
              </p>
              <p className="list-mark">
                <span>※</span>
                3階 MLB café内、女子トイレ内にも設置
              </p>
            </article>
            <article>
              <h4 className="title is-6">ベビーカー</h4>
              <p className="mb0">
                3Fインフォメーション横（上りエスカレーター付近）スペースにてお預け可能です。
                <br />
                鍵はございませんのでお客様にてご用意のうえご利用ください。
              </p>
              <p className="list-mark">
                <span>※</span>
                但し、紛失・盗難等の場合、弊社は一切の責任を負いませんのでご了承ください。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="喫煙室">
            <article>
              <p className="mb0">
                1F エントランス横 / 3F エレベーター横 / 7F トイレ横
              </p>
              <p className="list-mark">
                <span>※</span>
                3階は加熱式、電子タバコのみ喫煙可能です。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="コインロッカー(有料)">
            <article>
              <p className="mb0">1F エントランス外 / 3F 券売機付近</p>
            </article>
          </AccordionMin>
          <AccordionMin title="E・ZO FUKUOKAでご利用いただける決済手段">
            <article>
              <h4 className="title is-6">各施設</h4>
              <h5 className="ttl-strip">WEBでご購入の場合</h5>
              <p>
                VISA、マスター、JCB、アメックス、ダイナース、PayPay、アリペイ
              </p>
              <h5 className="ttl-strip">券売機でご購入の場合</h5>
              <p className="mb0">
                現金、VISA、マスター、JCB、アメックス、ダイナース、PayPay、アリペイ、ウィーチャット
              </p>
            </article>
            <article>
              <h4 className="title is-6">フードホール / MLB cafe</h4>
              <p className="mb0">
                現金、クレジットカード、電子マネー、QR決済(PayPay)
              </p>
              <p className="list-mark">
                <span>※</span>
                詳しくは店頭にてご確認ください。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="ATM">
            <article>
              <h4 className="title is-6">セブン銀行ATMについて</h4>
              <p>
                3F エスカレーター横
                <br />
                営業時間 : E・ZO FUKUOKAの営業時間に準じます
              </p>
              <p className="list-mark">
                <span>※</span>
                金融機関により、お取引できる時間が異なります。
              </p>
            </article>
            <article>
              <p>
                福岡銀行・ゆうちょ銀行など様々な銀行や証券会社、クレジット会社等のカードがご使用いただけます。この他、海外で発行されたクレジットカードやキャッシュカードでも手軽に円が引き出せます。ご利用できる金融機関等、詳しくは、セブン銀行のホームページにてご確認ください。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="AED">
            <article>
              <p>
                3F インフォメーション付近、4F 王貞治ベースボールミュージアム
                下りエスカレーター横、RF
                絶景三兄弟受付付近の3ケ所に、AED（自動体外式除細動器）を設置しております。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="団体でお越しのお客様">
            <article>
              <p>
                団体（20名様以上）利用の詳細は
                <a href="/group" className="tx-link">
                  こちら
                </a>
                をご覧ください。
              </p>
              <div className="btn-wrap mt-5">
                <Button href="/inquiry/group/" className="wide">
                  団体予約フォーム
                </Button>
              </div>
              <div className="mt-3">
                <p className="list-mark">
                  <span>※</span>
                  団体のお申込みは、20名様からとなります。
                </p>
                <p className="list-mark">
                  <span>※</span>
                  ご予約は、お申込み日の3週間先以降から（お支払いにクーポンご利用の旅行会社様は、2週間先以降から）となります。
                </p>
                <p className="list-mark">
                  <span>※</span>
                  お申込み後、弊社からの受付確認メールをもって、申込（受付）完了となります。
                </p>
              </div>
            </article>
          </AccordionMin>
          <AccordionMin title="イベントホールをご利用して催事をご検討のお客様">
            <article>
              <p>
                6階「イベントホール」は、最先端のアートからサブカルチャーまで様々な展示会、イベント等でご利用いただけます。
              </p>
              <p>
                詳しくは
                <a href="/eventhall/" className="tx-link">
                  こちら
                </a>
                をご覧ください。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="免税手続き">
            <article>
              <p className="mb20">
                対象店舗でご購入いただいた商品とレシート、パスポートを3F
                information（免税カウンター）までお持ちください。
              </p>
              <h4 className="title is-6">対象店舗</h4>
              <p>
                7F Sanrio characters Dream!ng Park／4F
                王貞治ベースボールミュージアム／3F MLB café FUKUOKA 物販
              </p>
              <p>
                詳しくは
                <a href="/tax-free/" className="tx-link">
                  こちら
                </a>
                をご覧ください。
              </p>
            </article>
          </AccordionMin>
          <AccordionMin title="その他">
            <article>
              <p>
                上記以外のお問い合わせにつきましては「
                <a
                  href="https://faq.softbankhawks.co.jp/category/show/128?site_domain=open"
                  className="tx-link"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  よくあるお問い合わせ
                </a>
                」をご確認ください。
              </p>
            </article>
          </AccordionMin>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
